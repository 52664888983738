/*
   @page Log detail
*/

//Import require libraries
import React,{ useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Helper from "./../../util/Helper";
import FormControl from '@mui/material/FormControl';


/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox:{
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
}
};


/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function log detail page logic
*/
const useStyles = makeStyles(styles);
export default function LogDetail() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [openSendReceipt, setOpenSendReceipt] = useState(false);
  const [openSMSReceipt, setOpenSMSReceipt] = useState(false);
  const [sendNumber, setSendNumber] = useState("");
  const [sendEmail, setSendEmail] = useState("");
  const [ecrRefNo, setEcrRefNo] = useState(0);
  const [amt, setAmt] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [bussinessName, setBussinessName] = useState("");
  const [mId, setMId] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [searchInResponse, setSearchInResponse] = useState("");
  useEffect(() => {
    const data = {};  
    getTransList(data);
  }, []);

/*
  @function get transaction list
*/
  const getTransList = (body) => {    
  const data = body;
    const headers = {
      'Authorization': 'Bearer '+localStorage.getItem('token')
  }
  axios.post(window.apiUrl+'get_log', data,{
    headers: headers
  }).then(response => {
         const userArr = [];
         response.data.forEach((eachLog) => {         
         var res = eachLog.respones.split("-----");   
         console.log(res);
         if(res.length == 3)
         {
          res = res[2];      
         }else{
          res = res[0];
         }
         console.log(res);
         let position = res.search("Object");            
         userArr.push([eachLog.id,res.substring(16,position),eachLog.business_name,eachLog.merchant_id,<textarea cols={40} rows={10}>{eachLog.respones}</textarea>,eachLog.serial_number,eachLog.created_at]);
        });          
        setTableData(userArr);
        
      }) .catch(error => {                
        return error;
      });
}

/*
  @function hendle search log
*/
const handleSearch = () =>{  
  setTableData(false);
  var data = "";
  if(startDate === "" || startDate === null || startDate === undefined) //Check id start date was null,blank or undefine
  {
    data = {'merchantId':mId,'businessName':bussinessName,'serialNumber':serialNumber,'response_search':searchInResponse};
    getTransList(data);  
  }else{    
  var sDate = new Date(startDate);
  sDate.setDate(sDate.getDate() + 1);
  if(endDate === null || endDate === "" || endDate === undefined) //Check id end date was null,blank or undefine
  {
    setEndDate(new Date());
    var eDate = new Date(new Date());
  }else{
    var eDate = new Date(endDate);
  }
  eDate.setDate(eDate.getDate() + 1);
  data = {'startDate':sDate.toISOString().slice(0, 10),'endDate':eDate.toISOString().slice(0, 10),'businessName':bussinessName,'merchantId':mId,'serialNumber':serialNumber,'response_search':searchInResponse}; 
  getTransList(data);  
}
  

}

/*
  * Close popup
*/
const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setError(false);
  setSuccess(false);
};

  if(!tableData){ //Check if table data not come than display loading dialog
    return (      
    <GridContainer>    

    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="warning">
          <h4 className={classes.cardTitleWhite}>Log List</h4>
          <p className={classes.cardCategoryWhite}>
          </p>
        </CardHeader>
        <CardBody>
          <center>
            <h3>Loading Log...</h3>
        <CircularProgress id="loginProgress" ></CircularProgress>
        </center>
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>);

  }
  return (
    <GridContainer>

      {/* 
        Display error message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* 
        display success message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

       {/* 
        Send email receipt start 
       */}
       <Dialog open={openSendReceipt} onClose={() => { setOpenSendReceipt(false)} } aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Send Email Receipt</DialogTitle>
          <DialogContent>
           <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Enter Email Address"
              type="email"
              onChange={(e) => setSendEmail(e.target.value)}
              value={sendEmail}              
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {setEcrRefNo(0);setSendEmail(""); setOpenSendReceipt(false)}} color="primary" style={{backgroundColor: '#ffa726', color: '#FFFFFF'}}>
              Cancel
            </Button>
            <Button onClick={()=>{sendEmailReceipt()}} color="primary" style={{backgroundColor: '#ffa726', color: '#FFFFFF'}}>
            Send
            </Button>
          </DialogActions>
        </Dialog>


         {/* 
            Send SMS receipt start 
         */}
       <Dialog open={openSMSReceipt} onClose={() => { setOpenSMSReceipt(false)} } aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Send SMS</DialogTitle>
          <DialogContent>
           <TextField
              autoFocus
              margin="25px"
              id="name"
              label="Enter Mobile Number"
              type="text"
              onChange={(e) => setSendNumber(e.target.value)}
              value={Helper.formatPhoneNumber(sendNumber)}              
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {setEcrRefNo(0);setSendNumber(""); setOpenSMSReceipt(false)}} color="primary" style={{backgroundColor: '#ffa726', color: '#FFFFFF'}}>
              Cancel
            </Button>
            <Button onClick={()=>{sendSMSReceipt()}} color="primary" style={{backgroundColor: '#ffa726', color: '#FFFFFF'}}>
            Send
            </Button>
          </DialogActions>
        </Dialog>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Log List</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
          <GridContainer>
             <GridItem xs={12} sm={12} md={3}>
              <div id="selectstartdate">
              <span>Start Date</span>
              <DatePicker style={{width:"100%"}} className={classes.datePickerTextBox} selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
              <div id="selectenddate">
              <span>End Date</span>
              <DatePicker className={classes.datePickerTextBox} selected={endDate} onChange={(date) => setEndDate(date)} />
              </div>
              </GridItem>
              
              <GridItem style={{marginTop:"8px"}} xs={12} sm={12} md={3}>
              <FormControl style={{ width: "100%",marginTop:'7px' }}>   
              <TextField                  
                  margin="dense"
                  id="outlined-basic"
                  variant="outlined" 
                  label="Business Name"
                  onChange = {(e) => {setBussinessName(e.target.value)}}
                  value={bussinessName}
                  fullWidth              
               />      
               </FormControl>        
              </GridItem>
              
              <GridItem style={{marginTop:"8px"}} xs={12} sm={12} md={3}>  
              <FormControl style={{ width: "100%",marginTop:'7px' }}>       
              <TextField                  
                  margin="dense"
                  id="outlined-basic"
                  variant="outlined" 
                  label="Merchant ID"
                  value={mId}
                  onChange = {(e) => {setMId(e.target.value)}}
                  fullWidth              
               /> 
               </FormControl>      
              </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem style={{marginTop:"8px"}} xs={12} sm={12} md={3}>
              <FormControl style={{ width: "100%",marginTop:'7px' }}>              
              <TextField                  
                  margin="dense"
                  id="outlined-basic"
                  variant="outlined"                    
                  label="Serial Number"
                  value={serialNumber}
                  onChange = {(e) => {setSerialNumber(e.target.value)}}
                  fullWidth              
               />  
               </FormControl>
              </GridItem>

              <GridItem style={{marginTop:"8px"}} xs={12} sm={12} md={3}>
              <FormControl style={{ width: "100%",marginTop:'7px' }}>              
              <TextField                  
                  margin="dense"
                  id="outlined-basic"
                  variant="outlined"                    
                  label="Search in Response"
                  value={searchInResponse}
                  onChange = {(e) => {setSearchInResponse(e.target.value)}}
                  fullWidth              
               />  
               </FormControl>
              </GridItem>
        
              <GridItem xs={12} sm={12} md={6} display="flex" justifyContent="flex-end">
              <Button id="btnGreenAdd" variant="contained" color="primary" style={{float:'right', marginTop:'20px', backgroundColor: '#ffa726', color: '#FFFFFF'}} onClick={handleSearch} className={classes.button}>
                  Search
                </Button>
              </GridItem>
              </GridContainer>
          
              
              {/* 
                Display log details
              */}
            
            <Table
              tableHeaderColor="primary"
              tableHead={["ID","Response Type","Bussiness Name","MId","Response","Serial Number","Created at"]}
              tableData={tableData}
            />

            
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
