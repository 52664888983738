import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from 'axios';
import Select from 'react-select';
import CircularProgress from '@material-ui/core/CircularProgress';

import avatar from "assets/img/faces/marc.jpg";
import { Switch } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import Helper from "./../../util/Helper";
import { website } from "variables/general";
import DatePicker from "react-datepicker";


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel2 from '@mui/material/InputLabel';
import Select2 from '@mui/material/Select';
import Moment from 'moment';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);


export default function UserProfile() {
  const classes = useStyles();

  const [userId, setUserId] = useState("");
  const [endDate, setEndDate] = useState("")
  const [startDate, setStartDate] = useState("");
  const [res, setres] = useState([{}]);

  

  function handleSubmit(event) {
    event.preventDefault();
    const data = { user_id:userId,startDate: startDate,endDate:endDate };
    const headers = {
    //   'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    axios.post(window.apiUrl+'trans_error_chk', data, {
      headers: headers
    }).then(response => {        
        setres(response.data);
    }).catch(error => {
      setErrorMsg("Fail To Update Settings.");
      setError(true);      
      return error;
    });

  }

  
  return (
    <div>
     
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form onSubmit={handleSubmit}>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Your Settings</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>                
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="user id"
                      id="businessname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Start Date"
                      id="streetaddress"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="End Date"
                      id="city"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>                
                
              </CardBody>

              <CardFooter>
                <Button type="submit" color="warning">Save</Button>
              </CardFooter>
            </form>                                    
            {
             res.forEach((eachTrans) => {     
                 if(parseFloat(eachTrans.trans_detail_total_price) != parseFloat(eachTrans.amount))
                 {
                    return console.log(eachTrans.trans_detail_total_price+" != "+eachTrans.amount+"  -> ecr_ref : "+eachTrans.ecrref_num);
                 }       
                     
                }) 

                
           }
          </Card>
        </GridItem>
      </GridContainer>    
    </div>
  );
}
