import React from "react";
import LoginPage from "views/Login/Login.js";
export default function Login() {
 
  return (
    <div>
      <LoginPage></LoginPage>
              
    </div>
  );
}
